import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth/services/auth.service';
import { TimeoutService } from '@auth/services/timeout.service';
import { LeaveOrStayComponent } from '@checkout/shared/dialogs/leave-or-stay/leave-or-stay.component';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { partialGrayedScreenModalConfig } from '@core/constants/modal.config';
import * as Util from '@core/utils';
import { Store } from '@ngrx/store';
import { selectTempemail } from '@store/core/core.selectors';
import { take, takeUntil } from 'rxjs';

@Directive({
  selector: '[pbbSignOut]',
  standalone: true,
})
export class SignOutDirective extends UntilDestroy implements OnInit {
  @Input()
  signOutEnabled?: boolean;
  @Input()
  prompt?: boolean = false;
  @Input()
  redirectURL?: string;

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private readonly timeoutService: TimeoutService,
    private authService: AuthService,
    private readonly store: Store
  ) {
    super();
  }
  ngOnInit() {
    this.store
      .select(selectTempemail)
      .pipe(take(1))
      .subscribe((email) => {
        if (email && !this.authService.authState().trusted_device) {
          this.redirectURL = '/auth/signin';
        }
      });
  }
  @HostListener('click')
  signout() {
    if (!this.signOutEnabled) {
      return;
    }
    if (!this.prompt) {
      this.authService.signout(this.redirectURL);
      return;
    }
    if (Util.isMobile()) {
      this.bottomSheet
        .open(LeaveOrStayComponent, {
          panelClass: 'content_main-drawer',
        })
        .afterDismissed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: any) => {
          if (result) {
            this.postSignout();
          }
        });
    } else {
      this.dialog
        .open(LeaveOrStayComponent, {
          ...partialGrayedScreenModalConfig,
          autoFocus: false,
        })
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result: any) => {
          if (result) {
            this.postSignout();
          }
        });
    }
  }

  postSignout() {
    this.timeoutService.cancelTimeout();
    this.authService.signout(this.redirectURL);
  }
}
