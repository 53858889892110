<div mat-dialog-content class="content_main-container timeout-container">
  <h1 class="mt-32 mb-16" *ngIf="timeleft > 0">
    {{ 'COMMON.TIMEOUT.SESSION_WILL_EXPIRE' | translate : { time: timeleft } }}
  </h1>
  <h4 *ngIf="timeleft <= 0" class="subtitle-1">
    {{ 'COMMON.TIMEOUT.SESSION_EXPIRED' | translate }}
  </h4>
  <span class="mat-body-2 mt-8 mb-24 text-dark-charcoal" *ngIf="timeleft <= 0">
    {{
      (isCheckout ? 'COMMON.TIMEOUT.RETURN_TO_CHECKOUT' : 'COMMON.TIMEOUT.RETURN_TO_LOGIN')
        | translate : { merchantName: this.merchantName }
    }}
  </span>
  <div class="flex-col" [ngClass]="'flex-end'">
    <button class="timeout-btn" mat-flat-button color="primary" [mat-dialog-close]="true" *ngIf="timeleft > 0">
      {{ 'COMMON.BUTTON.CONTINUE' | translate }}
    </button>
    <button class="timeout-btn" mat-flat-button color="primary" [mat-dialog-close]="true" *ngIf="timeleft <= 0">
      {{
        (isCheckout ? 'COMMON.TIMEOUT.RETURN_TO_CHECKOUT_BUTTON' : 'COMMON.TIMEOUT.RETURN_TO_LOGIN_BUTTON') | translate
      }}
    </button>
  </div>
</div>
