import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isMobile } from '@core/utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'pbb-leave-or-stay',
  templateUrl: './leave-or-stay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatButtonModule, TranslateModule],
})
export class LeaveOrStayComponent {
  isMobile = isMobile();
  titleKey = 'CHECKOUT.LEAVE_OR_STAY.TITLE';
  subtitleKey = 'COMMON.SIGNOUT.PROMPT.SUBTITLE';
  constructor(
    @Optional() private _bottomSheetRef?: MatBottomSheetRef<LeaveOrStayComponent>,
    @Optional() private dilogref?: MatDialogRef<LeaveOrStayComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData?: any,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?: any
  ) {
    this.isMobile = !this.dialogData?.isMobile ? this.dialogData?.isMobile : this.isMobile;
    this.titleKey = (this.isMobile ? this.bottomSheetData?.titleKey : this.dialogData?.titleKey) || this.titleKey;
    this.subtitleKey =
      (this.isMobile ? this.bottomSheetData?.subtitleKey : this.dialogData?.subtitleKey) || this.subtitleKey;
  }

  close(result?: boolean) {
    this._bottomSheetRef?.dismiss(result);
    this.dilogref?.close(result);
  }
}
