import { Inject, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth/services/auth.service';
import { TimeoutComponent } from '@checkout/shared/dialogs/timeout-dialog/timeout.component';
import { partialGrayedScreenModalConfig } from '@core/constants/modal.config';
import { APP_CONFIG } from '@core/interfaces/configs';
import { LoggerService } from '@core/services/logger.service';
import { APPCONFIG } from '@core/tokens/configs';
import { isCheckout } from '@core/utils';
import { fromEvent, Subject } from 'rxjs';

@Injectable()
export class TimeoutService {
  public warning$: Subject<boolean> = new Subject();
  public timeout$: Subject<boolean> = new Subject();
  private active: boolean = false;
  private countDown?: any;
  private countDownRefresh?: any;
  private isTimedOut: boolean = false;

  constructor(
    @Inject(APPCONFIG) readonly appConfig: APP_CONFIG,
    private dialog: MatDialog,
    private drawer: MatBottomSheet,
    private readonly logger: LoggerService,
    private authService: AuthService
  ) {
    this.warning$.subscribe({
      next: (v) => this.onWarning(),
    });
    this.timeout$.subscribe({
      next: (v) => this.onTimeout(),
    });
  }

  initilizeTimeout() {
    if (this.active) {
      return;
    }
    this.logger.log('TIMEOUT', 'INIT');
    this.active = true;
    fromEvent(document, 'keydown').subscribe(() => this.onInteraction());
    fromEvent(document, 'click').subscribe((e) => this.onInteraction());
    fromEvent(document, 'touchstart').subscribe(() => this.onInteraction());
    fromEvent(window, 'wheel').subscribe(() => this.onInteraction());
    this.onInteraction();
    this.initilizeRefreshTimeout();
  }

  initilizeRefreshTimeout() {
    if (!this.active) {
      return;
    }
    this.countDownRefresh && clearTimeout(this.countDownRefresh);
    this.countDownRefresh = setTimeout(() => {
      this.authService.refreshToken();
      this.initilizeRefreshTimeout();
    }, this.appConfig.sessionTimeout - this.appConfig.sessionWarningPeriod);
  }

  cancelTimeout() {
    this.logger.log('TIMEOUT', 'CLEAR');
    this.active = false;
    this.countDown && clearTimeout(this.countDown);
    this.countDownRefresh && clearTimeout(this.countDownRefresh);
  }

  onInteraction() {
    this.countDown && clearTimeout(this.countDown);
    this.countDown = setTimeout(() => {
      this.warning$.next(true);
    }, this.appConfig.sessionTimeout - this.appConfig.sessionWarningPeriod);
  }

  onWarning() {
    if (!this.active) {
      return;
    }
    if (!this.authService.authState().active_session) {
      this.cancelTimeout();
      return;
    }
    this.countDown && clearTimeout(this.countDown);
    this.countDown = setTimeout(() => {
      this.cancelTimeout();
      this.isTimedOut = true;
    }, this.appConfig.sessionWarningPeriod);
    this.dialog
      .open(TimeoutComponent, {
        ...partialGrayedScreenModalConfig,
        disableClose: true,
        autoFocus: false,
        data: {
          timer: this.appConfig.sessionWarningPeriod,
          isCheckout: isCheckout(),
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          if (this.isTimedOut) {
            this.dialog.closeAll();
            this.drawer.dismiss();
            this.timeout$.next(true);
          } else {
            this.onInteraction();
          }
        }
      });
  }

  onTimeout() {
    this.authService.signout();
  }
}
